import {FormikErrors} from "formik";
import {PaymentFormValues} from "./CheckoutPage";

const validate = (values:PaymentFormValues) => {
    let errors: FormikErrors<PaymentFormValues> = {};

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Email non valida';
    }

    if (!values.firstName) {
        errors.firstName = 'Nome non valido';
    }

    if (!values.lastName) {
        errors.lastName = 'Cognome non valido';
    }

    console.log(errors);


    return errors;
};

export function utf2Html(str:any) {
    return [...str].map((char) => char.codePointAt(0) > 127 ? `&#${char.codePointAt()};` : char).join('');
}

export default validate;
