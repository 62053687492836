import React, {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {BusinessProfile, PaymentStatus} from "../checkout/types";
import {useGetOrderStatusQuery} from "../checkout/api";
import AppLayout from "./AppLayout";
import {useTracker} from "../tracking/tracking.hook";


const WritingLayout: React.FC = () => {

    const {id} = useParams();

    const navigate = useNavigate();

    const tracker = useTracker();

    const order = useGetOrderStatusQuery(id as string)

    useEffect(() => {

        tracker.track('checkout', 'writing_page_loaded', id)

        if (!order.isSuccess && !order.isLoading) {
            tracker.track('checkout', 'writing_wrong_status', id)
            navigate('/payment-error/' + id);
        }

        if (order.isSuccess && order.currentData!.status === PaymentStatus.ERROR) {
            tracker.track('checkout', 'writing_error', JSON.stringify({id,status:order.currentData!.status}));
            navigate('/payment-error/' + id);
        }

        if (order.isSuccess &&
            (order.currentData!.status !== PaymentStatus.COMPLETED &&
            order.currentData!.status !== PaymentStatus.VERIFIED)
        ) {
            tracker.track('checkout', 'writing_error', JSON.stringify({id,status:order.currentData!.status}));
            navigate('/payment-error/' + id);
        }

    }, [id, navigate, order])

    return (
        <AppLayout fortuneTeller={order.currentData?.fortuneTeller.name}/>
    );

}

export default WritingLayout;
