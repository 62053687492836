import {FormikErrors} from "formik";
import {ConsultRequestFormValues} from "./ConsultRequestForm";
import {BusinessProfile, Service} from "../checkout/types";

const isEmpty = (str:string) => {
    return (!str || str.length === 0 );
}

const isValidFiscalCode = (str:string)=>{
    const regex = /[A-Za-z]{6}[0-9lmnpqrstuvLMNPQRSTUV]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9lmnpqrstuvLMNPQRSTUV]{2}[A-Za-z]{1}[0-9lmnpqrstuvLMNPQRSTUV]{3}[A-Za-z]{1}/;
    return regex.test(str);
}


const validate = (values:ConsultRequestFormValues, service: Service, hasBilling:boolean, businessProfile:BusinessProfile) => {
    let errors: FormikErrors<ConsultRequestFormValues> = {};

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Email non valida';
    }

    if(hasBilling){

        if(/^(\+?\d{1,3}|\d{1,4})$/.test(values.country)){
            errors.country = 'Nazione non valida';
        }

        if(isEmpty(values.city)){
            errors.city = 'Città non valida';
        }

        if(isEmpty(values.address)){
            errors.address = 'Indirizzo non valido';
        }

        if(isEmpty(values.firstName)){
            errors.firstName = 'Nome non valido';
        }

        if(isEmpty(values.lastName)){
            errors.lastName = 'Cognome non valido';
        }

        if(isEmpty(values.country)){
            errors.country = 'Nazione non valida';
        }

        if(values.country === 'it' && !isValidFiscalCode(values.fiscalCode)){
            errors.fiscalCode = 'Codice fiscale non valido';
        }

        if(isEmpty(values.postalCode)){
            errors.postalCode = 'Codice postale non valido';
        }
    }

    if(service.hasPhoto && isEmpty(values.image)){
        errors.image = 'seleziona un\'immagine';
    }


    if(service.hasChat && isEmpty(values.phone)){
        errors.phone = 'Inserisci un numero di telefono valido';
    }

    if(!service.hasChat){
        if(isEmpty(values.nameanddates) && businessProfile != 'MYC'){
            errors.nameanddates = 'Nomi e date non valide';
        }
        if(isEmpty(values.subject)){
            errors.subject = 'Titolo non valido';
        }
        if(isEmpty(values.content) || values.content.length <10){
            errors.content = 'Contenuto richiesta non valido';
        }
    }

    console.log(errors);

    return errors;
};

export default validate;
