import {api} from "../../api";
import {
    AddPaymentMethod,
    AddPaymentMethodResponse, BillingCity, Configurations,
    FinalizeOrderRequest,
    OrderResponse, OrderStatus, SearchOrderResponse,
    SendPrivateConsult, StoredContact, TimeRange
} from "./types";

export const checkoutApi = api.injectEndpoints({
    endpoints: (build) => ({
        getOrder: build.query<OrderResponse, string>({
            query: (id) => ({
                url: `/checkout/orders/${id}`,
            }),
        }),

        getConfigurations: build.query<Configurations, void>({
            query: () => ({
                url: `/checkout/configurations`,
            }),
        }),

        getStoredContacts: build.query<StoredContact, void>({
            query: () => ({
                url: `/checkout/contacts`,
            }),
        }),

        getCartomanteTurn: build.query<Map<string,TimeRange[]>, string>({
            query: (cartomante:string) => ({
                url: `/checkout/cartomante/${cartomante}/turn`,
            }),
        }),


        searchOrder: build.query<SearchOrderResponse, string>({
            query: (id) => ({
                url: `/checkout/orders/search`, params: {
                    transactionId: id
                }
            }),
        }),


        getBillingsCity: build.query<BillingCity[], void>({
            query: () => ({
                url: `/checkout/billings/city`,
            }),
        }),

        getOrderStatus: build.query<OrderStatus, string>({
            query: (id) => ({
                url: `/checkout/orders/${id}/status`,
            }),
        }),

        sendConsult: build.mutation<AddPaymentMethodResponse, SendPrivateConsult>({
            query(data) {
                const {id, ...body} = data
                return {
                    url: `/checkout/requests/${id}`,
                    method: 'PUT',
                    body,
                }
            },
            // transformResponse: (response: { data: AddPaymentMethodResponse }, meta, arg) => response.data,
        }),

        addPaymentMethod: build.mutation<AddPaymentMethodResponse, Partial<AddPaymentMethod>>({
            query(data) {
                const {id, ...body} = data
                return {
                    url: `/checkout/orders/${id}/add-payments-method`,
                    method: 'POST',
                    body,
                }
            },
            // transformResponse: (response: { data: AddPaymentMethodResponse }, meta, arg) => response.data,
        }),

        finalizeOrder: build.mutation<void, Partial<FinalizeOrderRequest>>({
            query(data) {
                const {id, ...body} = data
                return {
                    url: `/checkout/orders/${id}`,
                    method: 'PUT',
                    body: body
                }
            },
            // transformResponse: (response: { data: AddPaymentMethodResponse }, meta, arg) => response.data,
        }),
    }),

    overrideExisting: false,
})


export const {
    useGetCartomanteTurnQuery,
    useSearchOrderQuery,
    useGetConfigurationsQuery,
    useGetOrderQuery,
    useGetStoredContactsQuery,
    useGetBillingsCityQuery,
    useGetOrderStatusQuery,
    useAddPaymentMethodMutation,
    useSendConsultMutation,
    useFinalizeOrderMutation,
} = checkoutApi;
