import React from "react";
import EditableSelect, {Option} from "./EditableSelect";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../app/store";
import {addCity} from "./citySlice";
import {useGetBillingsCityQuery} from "../checkout/api";


interface Props {
    initialValue?:Option;
    country: string;
    onChange: (str?: Option) => void;
    errorMessage?: string;
}

const CitySelect: React.FC<Props> = ({country, onChange, errorMessage,initialValue}) => {

    const stateOptions = useSelector((state: RootState) => state.city)
    const dispatch = useDispatch()

    useGetBillingsCityQuery();

    return (
        <>
            <label>
                <span>Città</span>
                <EditableSelect initialValue={initialValue} placeholder={"Inserisci o seleziona la tuà città"} onSelect={city => {
                    if (city)
                        onChange(city);
                    else
                        onChange();
                }} onCreate={str => {
                    dispatch(addCity({option: str, country: country}))
                    onChange(str);
                }} defaultOptions={stateOptions[country]}/>
            </label>
            {errorMessage ? (<div style={{textAlign:"center", color:"red"}}>{errorMessage}</div>) : null}
        </>

    );
}
export default CitySelect;
