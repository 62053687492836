import React from "react";
import EditableSelect, {Option} from "./EditableSelect";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../app/store";
import {addAddress} from "./addressSlice";


interface Props {
    country: string;
    initialValue?:Option;
    onChange: (str?: string) => void;
    errorMessage?: string;
}


const AddressSelect: React.FC<Props> = ({country, onChange, errorMessage,initialValue}) => {

    const addressOptions = useSelector((state: RootState) => state.address)
    const dispatch = useDispatch()

    return (
        <>
            <label>
                <span>Indirizzo</span>
                <EditableSelect initialValue={initialValue} placeholder={"Inserisci o seleziona il tuo indirizzo"} onSelect={str => {
                    if (str)
                        onChange(str.label);
                    else
                        onChange();
                }} onCreate={str => {
                    dispatch(addAddress({option: str, country: country}))
                    onChange(str.label);
                }} defaultOptions={addressOptions[country]}/>
            </label>
            {errorMessage ? (<div style={{textAlign: "center", color: "red"}}>{errorMessage}</div>) : null}
        </>


    );
}
export default AddressSelect;
