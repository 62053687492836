import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHeart} from "@fortawesome/free-solid-svg-icons";



const IsaThankYouFormHeader = () => <div className="card-header bg_light_pan" style={{textAlign: "center"}}>
    <h3>
        <strong>
            <FontAwesomeIcon icon={faHeart}
                             style={{color: "red", marginRight: "5px"}}/>
            GRAZIE
            <FontAwesomeIcon icon={faHeart}
                             style={{color: "red", marginLeft: "5px"}}/>
        </strong>
    </h3>
</div>;

export default IsaThankYouFormHeader;
