import React, {Suspense} from "react";
import {Outlet} from "react-router-dom";
import Header from "../common/Header";
import BreadCamp from "../common/BreadCamp";
import {useGetConfigurationsQuery} from "../checkout/api";
import {BusinessProfile} from "../checkout/types";


interface Props {
    fortuneTeller?: string;
}

const AppLayout: React.FC<Props> = ({ fortuneTeller}) => {

    const configurations = useGetConfigurationsQuery().currentData;

    return (
        <Suspense fallback={<div>loading....</div>}>
          <div className={"page_container"}>
            <Header businessProfile={configurations?.businessProfile} siteDomain={configurations?.siteDomain}/>
            {configurations?.businessProfile === 'ISA' && <BreadCamp fortuneTeller={fortuneTeller} siteDomain={configurations?.siteDomain}/>}
            <Outlet/>
          </div>
        </Suspense>

    );

}

export default AppLayout;
