import {BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react'
import axios, {AxiosError, AxiosInstance, AxiosRequestConfig} from "axios";
import Cookies from 'js-cookie';

export const defaultAxios = axios.create({
    baseURL: "/api/", // YOUR_API_URL HERE
    headers: {
        "us-t-pdi":Cookies.get('us-t-pdi-v1'),
        "app-version":"1.0.0",
        "Content-Type": "application/json",
    },
});


const axiosBaseQuery = (jwtAxios:AxiosInstance): BaseQueryFn<
        {
            url?: string
            method?: AxiosRequestConfig['method']
            body?: AxiosRequestConfig['data']
            params?: AxiosRequestConfig['params']
        },
        unknown,
        unknown
        > =>
        async ({ url, method, body, params }) => {
            try {
                const result = await jwtAxios({ url:  url, method, data:body, params })
                return { data: result.data }
            } catch (axiosError) {
                let err = axiosError as AxiosError
                return {
                    error: {
                        status: err.response?.status,
                        data: err.response?.data || err.message,
                    },
                }
            }
        }

export const api = createApi({
    baseQuery: axiosBaseQuery(defaultAxios),
    endpoints: () => ({
    }),
})

