import IsaThankYouFormHeader from "./IsaThankYouFormHeader";
import {OrderStatus, PaymentStatus} from "../checkout/types";
import React from "react";
import {useTracker} from "../tracking/tracking.hook";

interface IsaThankYouPageProps {
  order?: OrderStatus,
  orderId?: string | undefined
}

const IsaThankYouPage = ({order, orderId}: IsaThankYouPageProps) => {

  const status = order?.status;

  const tracker = useTracker();

  const freeConsultId = order?.freeConsultId;

  return <div className="card">
    <IsaThankYouFormHeader/>
    <div className="card-body" style={{minHeight: "500px"}}>


      {
          (status && ![PaymentStatus.ALREADY_USED, PaymentStatus.COMPLETED, PaymentStatus.VERIFIED].find(el => {
            return el === status
          })) && <>
            <p className="CorpoMessaggio_Risposta center"><strong
                style={{fontSize: "28px", color: "darkgreen"}}>Ops... si è
              verificato un
              errore</strong></p>


            <p className="center">Riferimento:${orderId}</p>

            <p className="CorpoMessaggio_Risposta center"><strong>Contatta
              l'assistenza
              clienti</strong></p>
          </>
      }

      {(status && (status === PaymentStatus.ALREADY_USED)) &&
          <div style={{marginLeft: "auto", marginRight: "auto", textAlign: "center"}}>
            <p className="CorpoMessaggio_Risposta center"><strong
                style={{fontSize: "28px", color: "darkgreen"}}>La tua richiesta è
              stata inviata al cartomante.</strong></p>


            {freeConsultId &&
                <a role={"button"} className="btn btn-dark btn-lg"
                   href={'/consulti-cartomanzia-gratuiti/' + freeConsultId}

                >Vedi consulto Sbloccato</a>}

            <br/>
            <br/>
            <p className="CorpoMessaggio_Risposta center">
              <strong>
                <u>Ricordati di controllare la cartella della "posta
                  indesiderata" o "spam"!</u>
              </strong>
              <br/>
            </p>

          </div>}


      {(status && (status === PaymentStatus.COMPLETED || status === PaymentStatus.VERIFIED)) &&
          <div style={{marginLeft: "auto", marginRight: "auto", textAlign: "center"}}>
            <p className="CorpoMessaggio_Risposta center"><strong
                style={{fontSize: "28px", color: "darkgreen"}}>Acquisto
              completato.</strong></p>

            <p className="CorpoMessaggio_Risposta center"><strong>Se vuoi usare il
              servizio
              clicca bottone "Richiedi Consulto" oppure puoi usarlo quando vuoi
              aprendo il
              link che ti abbiamo inviato via email.</strong></p>


            <a onClick={() => {
              tracker.trackWith('checkout_thank_you', 'click_request_consult', {orderId})
            }} href={`/consult-request/${orderId}`} className="btn btn-dark btn-lg"
               role="button">Richiedi Consulto</a>


            {freeConsultId &&
                <a role={"button"} className="btn btn-dark btn-lg"
                   href={'/consulti-cartomanzia-gratuiti/' + freeConsultId}>Vedi consulto Gratuito</a>}

            <br/>
            <br/>
            <p className="CorpoMessaggio_Risposta center">
              <strong>
                <u>Ricordati di controllare la cartella della "posta
                  indesiderata" o "spam"!</u>
              </strong>
              <br/>
            </p>

          </div>}
    </div>

    {(status && status === PaymentStatus.VERIFY) &&
        <div className="thank-you-loading-wait-completion">
          <div className="lds-ellipsis">
            <span/>
            <span/>
            <span/>
          </div>
        </div>
    }


  </div>;
};

export default IsaThankYouPage;