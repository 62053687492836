import {Field, FieldHookConfig, useField} from "formik";
import {FieldAttributes} from "formik/dist/Field";
import React from "react";

interface Props {
    labelposition?: 'up'|'left'
}

const TextField = (props: FieldAttributes<any> & FieldHookConfig<string> & Props) => {
    const [field, meta] = useField(props);
    const errorText = meta.error && meta.touched ? meta.error : "";
    return (
        <>
            <label>
                <span>{props.label}</span>
                {props.labelposition !=='up' &&
					<Field
                        {...props}
                        {...field}/>
                }
            </label>
            {props.labelposition ==='up' &&
                <Field
                    {...props}
                    {...field}/>
            }
            {errorText ? (<div style={{textAlign:"center", color:"red"}}>{errorText}</div>) : null}
            </>
    );
};

export default TextField;
