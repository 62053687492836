import React from "react";
import {useGetCartomanteTurnQuery} from "../checkout/api";
import {TimeRange} from "../checkout/types";

export const TurnTable = ({cartomante}: { cartomante: string }) => {

    const {data, isLoading} = useGetCartomanteTurnQuery(cartomante);

    const builHours = (day:string, timeRanges:TimeRange[]|undefined) => {

      const response = timeRanges?.map(ti => {

            return  <div key={`${day}-${ti.from} - ${ti.to}`} className="price">{`${ti.from} - ${ti.to}`}</div>
        });
        return <>
            {response}
        </>;
    };

    const buildDay = (day:string) => {
        if(!data)
            return <></>;
        const map:Map<string, TimeRange[]> = new Map(Object.entries(data))
        return <td className="product_price">
            {map.has(day) && builHours(day,map.get(day))}
            {!map.has(day) && <div>Non disponibile</div>}
        </td>;
    };

    return data ? <div>
        <div style={{marginBottom: 20}}>Sarà disponibile nei seguenti orari:</div>
        <table className="table table-bordered text-center">
            <tbody>
            <tr className="pr_title">
                <td className="product_name">Giorno</td>
                <td className="product_name">Orari</td>
            </tr>
            <tr className="pr_price">
                <td className="product_name">Lunedi</td>
                {buildDay('MONDAY')}
            </tr>
            <tr className="pr_price">
                <td className="product_name">Martedi</td>
                {buildDay('TUESDAY')}
            </tr>
            <tr className="pr_price">
                <td className="product_name">Mercoledi</td>
                {buildDay('WEDNESDAY')}
            </tr>
            <tr className="pr_price">
                <td className="product_name">Giovedi</td>
                {buildDay('THURSDAY')}
            </tr>
            <tr className="pr_price">
                <td className="product_name">Venerdi</td>
                {buildDay('FRIDAY')}
            </tr>
            <tr className="pr_price">
                <td className="product_name">Sabato</td>
                {buildDay('SATURDAY')}
            </tr>
            <tr className="pr_price">
                <td className="product_name">Domenica</td>
                {buildDay('SUNDAY')}
            </tr>
            </tbody>
        </table>
    </div> : null;
};