export type PublicKeys ={
    PAYPAL:string;
    STRIPE:string;
}

export enum PaymentMethod {
    PAYPAL="PAYPAL", STRIPE="STRIPE", ACCOUNT="ACCOUNT"
}

export enum BusinessProfile {
    ISA="ISA", C24="C24", MYC="MYC",
}

export enum DiscountMode {
    TWO_FOR_ONE="TWO_FOR_ONE",
    THREE_FOR_ONE="THREE_FOR_ONE",
    FIXED="FIXED",
    NONE="NONE",
    PERCENT="PERCENT"
}


export type AddPaymentMethodResponse = {
    orderId: string;
    paymentId: string;
    paymentMethodId:string;
    ref:string;
}
export type OrderResponse = {
    key?: string;
    billing:boolean;
    publicKeys: PublicKeys;
    order: Order;
    businessProfile:BusinessProfile;
    service: Service;
    fortuneTeller:FortuneTeller;

};

export type Configurations = {
    siteDomain?: string;
    businessProfile: BusinessProfile;

};

export type TimeRange = {
    from:string;
    to:string;
}

export type StoredContact ={
    fiscalCode?:string;
    email?:string;
    firstName?:string;
    lastName?:string;
    address?:string;
    cap?:string;
    city?:string;
    country?:string;
}

export type SearchOrderResponse = {
    service: Service;
    businessProfile: BusinessProfile;
    cartomante:string;
    status:string

};

export type BillingCity = {
    city:string;
    cap:string;
}

export type FinalizeOrderRequest = {
    id:string;
    fullName:string;
    firstName:string;
    lastName:string;
    email:string;
}

export type Service ={
    id:number;
    discountMode:DiscountMode;
    maxWord:number;
    responseIn:number;
    instruction:string;
    imageName:string;
    hasFast:boolean;
    name:string;
    description:string;
    price:number;
    finalPrice:number;
    discount:number;
    isDiscount:boolean;
    hasPhoto:boolean;
    hasChat:boolean;
    image:string;
}

export enum PaymentStatus {
    CREATED="CREATED", VERIFIED="VERIFIED", COMPLETED="COMPLETED", ERROR="ERROR",
    VERIFY="VERIFY",WAIT_FOR_CONFIRMATION="WAIT_FOR_CONFIRMATION",ALREADY_USED="ALREADY_USED",REPLIED="REPLIED",NOT_FOUND="NOT_FOUND",
    CARTOMANTE_NOT_AVAILABLE="CARTOMANTE_NOT_AVAILABLE"
}

export type FortuneTeller = {
    name:string;
    imageUri:string;
    promo:boolean;
    isFast:boolean;
    isTurn:boolean;
    startTurn:string;
}

export type Order = {
    id:string;
    price: number;
    status: PaymentStatus;
    fortuneTeller:FortuneTeller;
    freeConsultId:string;
    cartomante:string;
    replyDate:string;
};

export type OrderStatus = {
    id:string;
    status: PaymentStatus;
    fortuneTeller:FortuneTeller;
    freeConsultId:string;
    replyDate:string;
};

export type SendPrivateConsult = {
    firstName:string;
    phone:string;
    lastName:string;
    city:string;
    countryCode:string;
    address:string;
    postalCode:string;
    storeContact?:boolean;
    id:string;
    ip?:string;
    email:string;
    nameAndDates?:string;
    subject:string;
    content?:string;
    cf?:string;
    imageContent?:string;
}

export type AddPaymentMethod = {
    id:string;
    paymentMethod:PaymentMethod;
}


