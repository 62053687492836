import React from "react";
import TextField from "../component/TextField";

interface Props {
    setFieldValue:any
}

export const CheckoutFormPageValues: React.FC<Props> = ({setFieldValue}) => {


    return (
        <>
            <section>
                <h2>Contatti</h2>
                <fieldset className="with-state">
                    <TextField label={"Nome"} name="firstName" id="firstName" className="field" placeholder="Inserisci Nome"
                                onChange={(value: any) => setFieldValue("firstName", value)}/>
                    <TextField label={"Cognome"} name="lastName" id="lastName" className="field" placeholder="Inserisci Cognome"
                               onChange={(value: any) => setFieldValue("lastName", value)}/>
                    <TextField label={"Email"} name="email" id="email" type="email" className="field" placeholder="Inserisci Email"
                                onChange={(value: any) => setFieldValue("email", value)} />
                </fieldset>
            </section>

        </>
    )
}
