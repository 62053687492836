import React from "react";
import {DiscountMode, Service} from "./types";

interface Props {
    service: Service
    showPrice: boolean;
    showInfo: boolean;
    promo: boolean;
}

const CheckoutServicePreview: React.FC<Props> = ({service, showPrice, showInfo, promo}) => {

    const serviceImage = () => {
        return '/images/servizi/' + service.image;
    }

    return (
        <div>
            {
                service.image &&
                <div className="justify-content-center" style={{margin: "32px 0"}}>
                    <div>
                        <img width="200px" height="200px"
                             style={{marginLeft: "auto", marginRight: "auto", display: "block"}} src={serviceImage()}
                             className="ProductImage-image" alt={"service"}/>
                    </div>
                </div>
            }
            {
                !service.image &&
                <div className="justify-content-center" style={{margin: "100px 0"}}>
                </div>
            }
            <div className="container" style={{marginTop: "30px", overflow: "visible"}} id="summary">
                <div className="row">
                    <span className="center-text"><b className="blocktext"
                                                     dangerouslySetInnerHTML={{__html: service.name}}/>
                    </span>

                </div>
                <div className="row" style={{marginTop: "30px"}}>
                    <span className="blocktext" dangerouslySetInnerHTML={{__html: service.description}}/>
                </div>


                {showPrice &&
                    <div id="order-total">
                        {service.isDiscount && <>
                            <div className="line-item subtotal">
                                <p className="label">Prezzo</p>
                                <p className="price" data-subtotal="">{service.price} €</p>
                            </div>
                            <div className="line-item shipping">
                                <p className="label">Sconto</p>
                                <p className="price">{service.discount} €</p>
                            </div>
                        </>}
                        <div className="line-item total">
                            <p className="label">Prezzo Finale</p>
                            <p className="price" data-total="">{service.finalPrice} €</p>
                        </div>
                    </div>
                }


                {
                    showInfo && <div className="order-total" style={{marginTop: "10px"}}>
                        <div className="line-item" style={{padding: "0px 30px 0 22px"}}>
                            <b>Parole che puoi scrivere massimo </b>
                            <b style={{marginLeft: "10px"}}>{service.maxWord}</b>
                        </div>
                        <div className="line-item" style={{padding: "0px 30px 0 22px"}}>
                            <b>Risposta entro </b>
                            <b style={{marginLeft: "10px"}}>{service.responseIn + " ore"} </b>
                        </div>
                    </div>
                }

                {
                    showPrice && promo && service.discountMode == DiscountMode.TWO_FOR_ONE &&
                    <div><h3><span style={{color: "#6a89e7"}} className="two_for_one">Offerta 2 X 1</span></h3>
                        <p style={{color: "#00a651"}}>Compra uno e ricevi due consulti, riceverai l'altro consulto sulla
                            mail
                            che hai usato durante acquisto</p>
                    </div>

                }
                {
                    showPrice && promo && service.discountMode == DiscountMode.THREE_FOR_ONE &&
                    <div><h3><span style={{color: "#6a89e7"}} className="two_for_one">Offerta 3 X 1</span></h3>
                        <p style={{color: "#00a651"}}>Compra uno e ricevi tre consulti, riceverai gli altri consulti
                            sulla mail
                            che hai usato durante acquisto</p>
                    </div>
                }


            </div>
        </div>
    );
}

export default CheckoutServicePreview;
