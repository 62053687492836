import React, {lazy, Suspense} from "react";
import {BusinessProfile} from "../checkout/types";

interface Props {
    businessProfile?: BusinessProfile
    siteDomain?:string;
}

const IsaHeader = lazy(() => import('./isa/IsaHeader'));
const MyCartomanteHeader = lazy(() => import('./mycartomante/MyCartomanteHeader'));




const Header: React.FC<Props> = ({businessProfile,siteDomain}) => {

    return (
        <>
            {businessProfile === BusinessProfile.ISA && siteDomain &&  <IsaHeader siteDomain={siteDomain}/>}
            {businessProfile === BusinessProfile.MYC && siteDomain &&  <MyCartomanteHeader siteDomain={siteDomain}/>}
        </>
    );

}

export default Header;
