import {Formik, FormikHelpers} from "formik";
import React, {useRef, useState} from "react";
import validate from "./utils";
import {BusinessProfile, Service} from "../checkout/types";
import TextField from "../component/TextField";
import HtmlEditorComponent from "../component/HtmlEditorComponent";
import CountrySelect from "react-bootstrap-country-select";
import 'bootstrap/dist/css/bootstrap.css';
import 'react-bootstrap-country-select/dist/react-bootstrap-country-select.css';
import {COUNTRIES} from "../component/countries";
import CitySelect from "../component/CitySelect";
import AddressSelect from "../component/AddressSelect";
import FileUpload from "../component/FileUpload";
import {useGetStoredContactsQuery, useSendConsultMutation} from "../checkout/api";
import {utf2Html} from "../checkout/utils";

export interface Props {
    service: Service;
    businessProfile:BusinessProfile;
    saveBillingData: boolean;
    id: string;
    billing:boolean;
}


export interface ConsultRequestFormValues {
    email: string;
    saveBillingData: boolean;
    lastName: string;
    address: string;
    city: string;
    firstName: string;
    postalCode: string;
    fiscalCode: string;
    country: string;
    image: string;
    nameanddates: string;
    subject: string;
    phone: string;
    content: string;
}


const labelsByBp= {

    'ISA':{
        titleLabel:'Titolo',
        titlePlaceholder:'Inserisci il titolo della richiesta',
        requestPlaceholder:'Scrivi la tua richiesta'
    },
    "C24":{
        titleLabel:'Titolo',
        titlePlaceholder:'Inserisci il titolo della richiesta',
        requestPlaceholder:'Scrivi la tua richiesta'

    },
    "MYC":{
        titleLabel:'Argomento',
        titlePlaceholder:'Argomento',
        requestPlaceholder:'La tua richiesta'
    }
}

const NameAndDatesIsa = (props: { onChange: (value: any) => void }) => {
    return <TextField label={"Nomi e Date"} name="nameanddates" id="nameanddates" className="field"
                      placeholder="Inserisci Nomi e Date"
                      onChange={props.onChange}/>;
};

const NameAndDatesMyc = (props: { onChange: (value: any) => void }) => {

    return <>
    </>;
};

const NameAndDates = (props: { onChange: (value: any) => void, businessProfile:BusinessProfile}) => {


    if(props.businessProfile === 'MYC'){
        return <NameAndDatesMyc onChange={props.onChange}/>
    }

    return <NameAndDatesIsa onChange={props.onChange}/>
};

const ConsultRequestForm: React.FC<Props> = ({id, service, saveBillingData,billing,businessProfile}) => {

    const useGetBillingsCityQuery = useGetStoredContactsQuery();

    let currentData = useGetBillingsCityQuery.currentData;


    const initialValues: ConsultRequestFormValues = {
        email: currentData?.email ? currentData.email : '',
        lastName: currentData?.lastName ? currentData.lastName : '',
        firstName: currentData?.firstName ? currentData.firstName : '',
        city: currentData?.city ? currentData.city : '',
        address: currentData?.address ? currentData.address : '',
        postalCode: currentData?.cap ? currentData.cap : '',
        fiscalCode: currentData?.fiscalCode ? currentData.fiscalCode : '',
        country: currentData?.country ? currentData.country : 'it',
        image: '',
        nameanddates: '',
        subject: '',
        phone: '',
        content: '',
        saveBillingData: !!currentData?.email
    };

    const [sendConsult, {isLoading: isUpdating}] = useSendConsultMutation()

    const onSubmit = (values: ConsultRequestFormValues, formikHelpers: FormikHelpers<ConsultRequestFormValues>) => {
        let content = values.content;
        let subject = values.subject;

        console.log(content);

        let nameanddates = values.nameanddates;

        if (service.hasChat) {
            content = 'richiesta consulto via chat al numero ' + values.phone;
            subject = 'consulto via chat';
        }

        if(businessProfile == 'MYC'){
            nameanddates = '------';
        }

        const result = sendConsult({
            id: id,
            nameAndDates: utf2Html(nameanddates),
            subject: utf2Html(subject),
            content: utf2Html(content),
            cf: values.fiscalCode,
            firstName: values.firstName,
            lastName: values.lastName,
            city: values.city,
            countryCode: values.country,
            address: values.address,
            postalCode: values.postalCode,
            storeContact: values.saveBillingData,
            email: values.email,
            phone: values.phone,
            imageContent: values.image
        }).unwrap();


        result.then(function () {
            document.location = '/thank-you/' + id;
        });

        formikHelpers.setSubmitting(isUpdating);
    }

    const applyValidation = (values: ConsultRequestFormValues) => {

        return validate(values, service, billing, businessProfile);
    }


    return (
        <Formik enableReinitialize={true} validateOnChange={true} validateOnBlur={false} validateOnMount={false}
                validate={applyValidation}
                initialValues={initialValues} onSubmit={onSubmit}>
            {({handleSubmit, isSubmitting, values, isValid, setFieldValue, errors, touched, setFieldTouched}) => (
                <form onSubmit={handleSubmit}>
                    <section>
                        <h2>Contatti</h2>
                        <fieldset className="with-state">
                            <TextField label={"Email"} name="email" id="email" className="field"
                                       placeholder="Inserisci Email"
                                       onChange={(value: any) => setFieldValue("lastName", value)}/>
                        </fieldset>
                    </section>


                    {billing && <section>
                        <h2>Fatturazione</h2>

                        <fieldset className="with-state">

                            <label>
                                <span>Nazione</span>
                                <CountrySelect className={"field"}
                                               countries={COUNTRIES}
                                               value={values.country}
                                               placeholder={"Seleziona Nazione"}
                                               flush={true}
                                               valueAs='id'
                                               onTextChange={(v) => {
                                                   setFieldValue('country', v)
                                               }}
                                               onChange={(v) => {
                                                   setFieldValue('country', v?.toString())
                                               }}/>
                            </label>

                            <TextField label={"Codice Fiscale"} disabled={values.country !== 'it'} name="fiscalCode"
                                       id="fiscalCode" className="field"
                                       placeholder="Inserisci il codice fiscale"
                                       onChange={(value: any) => setFieldValue("fiscalCode", value)}/>

                            <label style={{color: "#32325d"}}>Se sei in un paese estero non è obbligatorio il codice
                                fiscale, scegli dal menu lo stato</label>
                        </fieldset>

                        <fieldset className="with-state" style={{marginBottom: "10px"}}>
                            <TextField label={"Cognome"} name="lastName" id="lastName" className="field"
                                       placeholder="Inserisci Cognome"
                                       onChange={(value: any) => setFieldValue("lastName", value)}/>
                            <TextField label={"Nome"} name="firstName" id="firstName" className="field"
                                       placeholder="Inserisci Nome"
                                       onChange={(value: any) => setFieldValue("firstName", value)}/>

                            <AddressSelect initialValue={{label: initialValues.address, value: initialValues.address}}
                                           errorMessage={(touched.address && errors.address) ? errors.address : ''}
                                           country={values.country} onChange={(address) => {
                                setFieldValue('address', address)
                                setFieldTouched('address', true, false)
                            }
                            }/>
                            <CitySelect initialValue={{value: initialValues.postalCode, label: initialValues.city}} errorMessage={(touched.city && errors.city) ? errors.city : ''}
                                        country={values.country} onChange={(city) => {

                                if (city) {
                                    if (city.value) {
                                        setFieldValue('postalCode', city.value, false)
                                        setFieldTouched('postalCode', false, false)
                                    }

                                    setFieldValue('city', city.label)
                                    setFieldTouched('city', true, false)

                                } else {
                                    setFieldValue('city', '')
                                    setFieldTouched('city', true, false)

                                }

                            }
                            }/>

                            <TextField onChange={(value: any) => setFieldValue("postalCode", value)}
                                       label={"Codice Postale"} name="postalCode" id="postalCode" className="field"
                                       placeholder="Inserisci codisce postale" required/>

                        </fieldset>

                        <fieldset>
                            <div className="chek-form" style={{marginBottom: "20px"}}>
                                <div className="custome-checkbox checkbox-wrapper">
                                    <input className="form-check-input" type="checkbox" name="savecontact" checked={values.saveBillingData}
                                           id="savecontact" value="" onChange={(ev: any) => {
                                        setFieldValue('saveBillingData', ev.target.checked)
                                    }}/>


                                    <label className="form-check-label label-to-fix" htmlFor="savecontact"><span style={{marginBottom: "10px"}}>Salva i dati di fatturazione</span></label>
                                    <label style={{height: "75px"}}><span style={{textAlign: "left"}}>
                                                    I tuoi dati verranno salvati per questo dispositivo in modo sicuro
                                                    e verranno usati solo per gli acquisti successivi <br/>
                                        <b> Non verranno salvati i dati della tua carta</b></span></label>
                                </div>
                            </div>

                        </fieldset>


                    </section>}

                    <section>
                        <h2>Consulto</h2>
                        <fieldset className="with-state">
                            {service.hasChat && <>

                                <TextField required label={"Telefono"} name="phone" id="phone" className="field"
                                           placeholder="Inserisci un numero di telefono valido"
                                           onChange={(value: any) => {
                                               setFieldValue("subject", value);
                                               setFieldValue("content", "Richiedo un consulto via chat al numero " + value);
                                           }}/>

                            </>}

                            {!service.hasChat && <>

                                <TextField label={labelsByBp[businessProfile].titleLabel} name="subject" id="subject" className="field"
                                           placeholder={labelsByBp[businessProfile].titlePlaceholder}
                                           onChange={(value: any) => setFieldValue("subject", value)}/>

                                <NameAndDates businessProfile={businessProfile} onChange={(value: any) => setFieldValue("nameanddates", value)}/>

                                <div className="form-group col-md-12">
                                    <label className={"description"}>{labelsByBp[businessProfile].requestPlaceholder}<span
                                        className="required">*</span></label>
                                    <HtmlEditorComponent
                                        errorMessage={(touched.content && errors.content) ? errors.content : ''}
                                        maxWord={service.maxWord} onChangeText={(v) => {
                                        setFieldValue('content', v);
                                        setFieldTouched('content', true, true);
                                    }
                                    }/>
                                </div>


                            </>}

                            {service.hasPhoto &&
                                <FileUpload errorMessage={(errors.image) ? errors.image : ''} onFileLoaded={vl => {
                                    setFieldValue('image', vl);
                                }
                                }/>
                            }

                        </fieldset>


                    </section>

                    <div className="col-md-12 mx-auto col-md-offset-6" style={{marginTop: "10px"}}>
                        <div>
                            <button disabled={!isValid && isUpdating} type="submit" className="btn btn-fill-out"
                                    name="submit" value="Submit">
                                Invia
                            </button>
                        </div>
                    </div>

                </form>
            )}

        </Formik>
    );
}

export default ConsultRequestForm;
