import React, {useEffect} from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import CheckoutPage from "./features/checkout/CheckoutPage";
import ThankYouPage from "./features/thank-you/ThankYouPage";
import PaymentErrorPage from "./features/payment-error/PaymentErrorPage";
import CheckoutLayout from "./features/layout/CheckoutLayout";
import WritingLayout from "./features/layout/WritingLayout";
import OrderConsultRequest from './features/consult-request/OrderConsultRequest';
import TxIdConsultRequest from "./features/consult-request/TxIdConsultRequest";
import WritingLayoutTx from "./features/layout/WritingLayoutTx";
import {useTracker} from "./features/tracking/tracking.hook";


function CheckoutApp() {

    const tracker = useTracker();

    useEffect(() => {
        const errorHandler = (error:any) => {
            tracker.trackWith('checkout', 'generic_error', {url: window.location.href,error})
        };

        window.addEventListener('error', errorHandler);

        return () => {
            window.removeEventListener('error', errorHandler);
        };
    }, []);


    return (
        <BrowserRouter>
            <Routes>
                <Route element={<CheckoutLayout/>}>
                    <Route path="/:id" element={<CheckoutPage/>}/>
                    <Route path="/checkout/:id" element={<CheckoutPage/>}/>
                    <Route path="/thank-you/:id" element={<ThankYouPage/>}/>
                </Route>
                <Route element={<WritingLayout/>}>
                    <Route path="/consult-request/:id" element={<OrderConsultRequest/>}/>
                    <Route path="/payment-error/:id" element={<PaymentErrorPage/>}/>
                </Route>
                <Route element={<WritingLayoutTx/>}>
                    <Route path="/consulto-privato" element={<TxIdConsultRequest/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default CheckoutApp;
