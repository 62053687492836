import {api} from "../../api";
import {IpInfoResponse, TrackRequest} from "./types";

export function getIpInfo():Promise<IpInfoResponse>  {
    return fetch(`https://ipinfo.io/json`, {
        signal: AbortSignal.timeout(1000),
    })
    .then(response => {
        if (!response.ok) {
            return Promise.resolve({ip: '0.0.0.0'} as IpInfoResponse);
        }
        return response.json() as Promise<IpInfoResponse>
    }).catch(val => {
        return Promise.resolve({ip: '0.0.0.0'} as IpInfoResponse);
    });
}


const auditsApi = api.injectEndpoints({
    endpoints: (build) => ({

        audit: build.mutation<void, Partial<TrackRequest>>({

            query(data) {
                const { ...body } = data
                return {
                    url: `/checkout/audits`,
                    method: 'POST',
                    body,
                }
            },
        })

    }),
    overrideExisting: false,
})

export const {
    useAuditMutation,
} = auditsApi;


