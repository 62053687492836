import React, {Component, useRef, useState} from "react";
import {Editor} from "@tinymce/tinymce-react";
import {IAllProps} from "@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor";


interface HtmlEditorComponentProps extends IAllProps {
    placeholder?: string;
    onChangeText: (str?: string) => void;
    defaultValue?: string;
    onSave?: any;
    errorMessage?: string;
    height?: number;
    maxWord: number;
}

const HtmlEditorComponent: React.FC<HtmlEditorComponentProps> = ({
                                                                     errorMessage,
                                                                     maxWord,
                                                                     placeholder,
                                                                     onChangeText,
                                                                     defaultValue,
                                                                     onSave,
                                                                     height
                                                                 }) => {

    const editorRef = useRef(null);

    const [saving, setSaving] = useState(false);
    const [lastValue, setLastValue] = useState(defaultValue || "");

    const [value, setValue] = React.useState(defaultValue ?? "");

    const [length, setLength] = useState(0);


    const handleUpdate = (value: any, editor: any) => {
        const length = editor.plugins.wordcount.body.getWordCount();
        if (length <= maxWord) {
            setValue(value);
            setLength(length);
            onChangeText(editor.getContent({format: "text"}));
        }
    };

    const handleBeforeAddUndo = (evt: any, editor: any) => {
        const length = editor.plugins.wordcount.body.getWordCount();
        // note that this is the opposite test as in handleUpdate
        // because we are determining when to deny adding an undo level
        if (length > maxWord) {
            evt.preventDefault();
        }
    };

    const handleInit = (evt: any, editor: any) => {
        setLength(editor.getContent({format: "text"}).length);
    };

    // @ts-ignore
    return (
        <>
            <Editor
                onBlur={async (inst: any) => {

                    if (!onSave) {
                        return;
                    }

                    // @ts-ignore
                    let val = editorRef.current.getContent();

                    if (val !== lastValue) {
                        setSaving(true);
                        try {
                            onSave && (await onSave(val));
                            setSaving(false);
                            setLastValue(val);
                        } catch (err) {
                            setSaving(false);
                            console.error('we had a save error')
                        }
                    }
                }}

                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                onInit={(evt: any, editor: any) => {
                    handleInit(evt, editor)
                    return editorRef.current = editor;

                }}
                value={value}
                initialValue={defaultValue}
                onEditorChange={handleUpdate}
                onBeforeAddUndo={handleBeforeAddUndo}
                apiKey={"rv51mdd31014s3sbdczlm080iu48ozzzgmjk8amscg89xtlx"}
                init={{
                    relative_urls: false,
                    height: height ? height : 300,
                    menubar: false,
                    plugins: 'lists wordcount',
                    toolbar: 'undo redo formatselect ' +
                        'bold italic forecolor alignleft aligncenter ' +
                        'alignright alignjustify bullist numlist ',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}/>
            {errorMessage && <p style={{color: 'red',marginLeft: '10px'}}>{errorMessage}</p>}
            <p style={{marginLeft: '10px'}}>Rimaste: {maxWord - length} Parole</p>

        </>
    );

}


export default HtmlEditorComponent;
