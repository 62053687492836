import {createOption, Option} from "./EditableSelect";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {api} from "../../api";
import {checkoutApi} from "../checkout/api";
import {BillingCity} from "../checkout/types";



export const stateOptionsCH: Option[] = [
    {label: 'Lugano',value:'6900'},
    {label: 'Zurigo',value:'8000'},
    {label: 'Lucerna',value:'6003'},
    {label: 'Mendrisio',value:'6825'},
    {label: 'Chiasso',value:'6830'},
    {label: 'Ginevra',value:'1201'},
    {label: 'Berna',value:'3001'},
    {label: 'Losanna',value:'1000'},
    {label: 'Basilea',value:'4001'},

];

export const stateOptionsFR: Option[] = [
    {label: 'Parigi',value:'70123'},
    {label: 'Marsiglia',value:'13000'},
    {label: 'Lione',value:'69000'},
    {label: 'Tolosa',value:'31000'},
    {label: 'Nizza',value:'06000'},
    {label: 'Nantes',value:'44000'},
    {label: 'Montpellier',value:'34000'}

];


export const stateOptionsES: Option[] = [
    {label: 'Madrid',value:'28001'},
    {label: 'Bilbao',value:'48001'},
    {label: 'Barcellona',value:'080001'},
    {label: 'Siviglia',value:'41001'},
    {label: 'Valencia',value:'46001'},
    {label: 'Toledo',value:'45001'},
    {label: 'Malaga',value:'29001'}
];


export const stateOptionsDE: Option[] = [
    {label: 'Berlino',value:'10115'},
    {label: 'Amburgo',value:'20095'},
    {label: 'Monaco',value:'80331'},
    {label: 'Colonia',value:'50667'},
    {label: 'Francoforte sul Meno',value:'60306'},
    {label: 'Stoccarda',value:'79173'},
    {label: 'Düsseldorf',value:'40210'}
]

export const stateOptionsUK: Option[] = [
    {label: 'Londra',value:'E1W'},
    {label: 'Birmingham',value:'B20'},
    {label: 'Manchester',value:'M1'},
    {label: 'Sheffield',value:'S1'},
    {label: 'Liverpool',value:'L75'},
    {label: 'Nottingham',value:'NG1'},
    {label: 'Leeds',value:'BD11'},
    {label: 'Bristol',value:'BS1'}
];

export const stateOptionsUS: Option[] = [
    {label: 'New York',value:'10001'},
    {label: 'Boston',value:'02108'},
    {label: 'Los Angeles',value:'90001'},
    {label: 'Las Vegas',value:'88901'},
    {label: 'Chicago',value:'60007'},
    {label: 'Washington',value:'20001'},
    {label: 'Houston',value:'77001'},
    {label: 'Filadelfia',value:'19019'},
    {label: 'San Francisco',value:'94016'}
];

export const initialState: Record<string, Option[]> = {
    "it": [],
    "uk": stateOptionsUK,
    "de": stateOptionsDE,
    "fr": stateOptionsFR,
    "us": stateOptionsUS,
    "ch": stateOptionsCH,
    "es": stateOptionsES
}

interface AddCity {
    option: Option;
    country: string;
}


export const citySlice = createSlice({
    name: 'city',
    initialState,
    reducers: {
        addCity: (state, action: PayloadAction<AddCity>) => {
            if (state[action.payload.country]) {
                state[action.payload.country].push(action.payload.option)
            }
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            checkoutApi.endpoints.getBillingsCity.matchFulfilled,
            (state, action) => {
                const payload1 = action.payload;
                state['it']=  payload1.map(val => <Option>{
                    label: val.city,
                    value:val.cap
                });
            }
        )
    },
})

export const {addCity} = citySlice.actions

