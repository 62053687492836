import React, {useState} from 'react';

import {Elements} from '@stripe/react-stripe-js';
import {StripePaymentElement} from "./StripePaymentElement";
import {Stripe} from "@stripe/stripe-js/types/stripe-js";
import {useGetOrderQuery} from "../checkout/api";
import {loadStripe} from "@stripe/stripe-js";

interface Props {
    checkoutId:string;
    disabled:boolean;
    email:string;
    onPaymentConfirmed: any;
}

const StipePayment: React.FC<Props> = ({checkoutId,onPaymentConfirmed, email,disabled}) => {

    const [stripePromise, setStripePromise] = useState<Promise<Stripe>>();

    let response = useGetOrderQuery(checkoutId);
    if(response.currentData && !stripePromise ){
        const publishableKey = response.currentData.publicKeys.STRIPE;
        let promise = loadStripe(publishableKey);
        if(promise) { // @ts-ignore
            setStripePromise(promise);
        }
    }


    return (
        <>
            {stripePromise && response.currentData && (
                <Elements stripe={stripePromise}>
                    <StripePaymentElement
                        email={email}
                        disabled={disabled}
                        checkoutId={checkoutId}
                        onPaymentConfirmed={onPaymentConfirmed}
                        amount={response.currentData!.order.price}
                    />
                </Elements>
            )}
        </>
    );
}


export default StipePayment;
