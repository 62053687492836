import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';

type FilePreview = {
    preview: string;
    name: string;
}

interface Props {
    onFileLoaded: (str: string | ArrayBuffer | null) => void;
    errorMessage?: string;
}

const FileUpload: React.FC<Props> = ({onFileLoaded,errorMessage}) => {

    const [file, setFile] = useState<FilePreview>();

    const {getRootProps, getInputProps} = useDropzone({
        accept: {
            'image/*': []
        },
        onDrop: (acceptedFiles: File[]) => {
            if (acceptedFiles.length > 0) {
                const map = acceptedFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                }));
                setFile(map[0]);
                const reader = new FileReader();
                reader.onloadend = () => {
                    onFileLoaded(reader.result)
                };
                reader.readAsDataURL(map[0]);
            }


        }
    });


    useEffect(() => {
        return () => {
            if (file) {
                return URL.revokeObjectURL(file.preview);
            }
        }
    }, [file]);

    const errorStyle = errorMessage ? {borderColor:'red',color:'red'} : {};

    return (<>
        <section className="container">
            <div {...getRootProps({className: 'dropzone'})} style={errorStyle}>
                <input {...getInputProps()} />
                <p>Trascina immagine o clicca</p>
            </div>
            {errorMessage && <p style={errorStyle}>Selezionare un'immagine</p>}
            <aside style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                marginTop: 16
            }}>

                {file && <div style={{
                    display: 'inline-flex',
                    borderRadius: 2,
                    border: '1px solid #eaeaea',
                    marginBottom: 8,
                    marginRight: 8,
                    width: "50%",
                    height: "50%",
                    padding: 4,
                    boxSizing: 'border-box'
                }} key={file.name}>
					<div style={{
                        display: 'flex',
                        minWidth: 0,
                        overflow: 'hidden'
                    }}>
						<img src={file.preview}
							 style={{
                                 display: 'block',
                                 width: 'auto',
                                 height: '100%'
                             }}
							 onLoad={() => {
                                 URL.revokeObjectURL(file.preview)
                             }}
							 alt={""}/>
					</div>
				</div>}
            </aside>
        </section>
    </>);
}

export default FileUpload;
