import React, {useState} from "react";
import CreatableSelect from "react-select/creatable";

export type Option = {
    readonly label: string;
    readonly value?:string;
};


interface Props {
    initialValue?:Option;
    defaultOptions: Option[];
    placeholder: string;
    onCreate: (str: Option) => void;
    onSelect: (str: Option | null | undefined) => void;
}

export const createOption = (label: string) => ({
    label
});

const EditableSelect: React.FC<Props> = ({defaultOptions, onSelect, placeholder, onCreate,initialValue}) => {

    const [value, setValue] = useState<Option | null | undefined>();

    console.log(value);

    const optionDef = defaultOptions ? defaultOptions.find(v => (initialValue && v.label === initialValue?.label)):null;

    return (<>
            <CreatableSelect
                placeholder={placeholder}
                value={value === undefined ? optionDef ? optionDef : undefined : value}
                createOptionPosition={"first"}
                formatCreateLabel={(str) => str}
                onChange={(newValue) => {
                    setValue(newValue);
                    onSelect(newValue);
                }}
                onCreateOption={(val) => {
                    const option = createOption(val);
                    setValue(option);
                    onCreate(option)
                }}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderStyle: 'none',
                        padding: "0px 0px"
                    }),
                    valueContainer: (baseStyles, state) => ({
                        ...baseStyles,
                        padding: "0px 0px"
                    }),
                    placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "#8898aa",
                        padding: "0px 0px"
                    }),
                    indicatorSeparator: (baseStyles, state) => ({
                        ...baseStyles,
                        display: "none",
                    }),

                }} className={"field"} isClearable options={defaultOptions}/>
        </>
    );
}
export default EditableSelect;
