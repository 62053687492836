import React, {useMemo} from "react";
import {useTracker} from "../tracking/tracking.hook";
import ConsultRequestForm from "./ConsultRequestForm";
import CheckoutServicePreview from "../checkout/CheckoutServicePreview";
import {BusinessProfile, Service} from "../checkout/types";

interface Props {
    service?: Service;
    businessProfile:BusinessProfile;
    id:string;
    fortuneTeller?: string;
    billing:boolean;
}

const ConsultRequest: React.FC<Props> = ({service,fortuneTeller,id,billing,businessProfile}) => {

    const tracker = useTracker();

    useMemo(() => {
        tracker.track('request_private_consult', 'page_loaded', id)
    }, [id]);

    return (
        <div className={"main_content"}>
            <div className={"container section checkout-page"}>
                {fortuneTeller && service &&
					<div className={"row"}>
						<div className={"col-md-7 col-xs-12"}>

							<div className={"App-Overview"}>
								<div className="card-header bg_light_pan" style={{textAlign: 'center'}}>

									<strong dangerouslySetInnerHTML={{__html: service.name}}/>
									<strong>
                                        {' con ' + fortuneTeller}
									</strong>

								</div>
								<main id={"main"}>
									<div id={""}>
										<ConsultRequestForm businessProfile={businessProfile} service={service} saveBillingData={false}
															id={id as string} billing={billing}/>
									</div>
								</main>
							</div>

						</div>
						<div className={"col-md-5 col-xs-12"}>
                            {service &&
								<CheckoutServicePreview service={service} showPrice={false}
														showInfo={true} promo={false}/>}
						</div>
					</div>
                }
            </div>
        </div>
    )
}

export default ConsultRequest
