import React, {useMemo} from "react";
import {useParams} from "react-router-dom";
import {useGetConfigurationsQuery, useGetOrderStatusQuery} from "../checkout/api";
import {useTracker} from "../tracking/tracking.hook";
import IsaThankYouPage from "./IsaThankYouPage";
import MycThankYouPage from "./MycThankYouPage";

const ThankYouPage: React.FC = () => {

    let {id} = useParams();

    const order = useGetOrderStatusQuery(id as string)

    const configurations = useGetConfigurationsQuery().currentData;

    const currentData = order.currentData;
    const status = currentData?.status;

    const tracker = useTracker();

    const freeConsultId = currentData?.freeConsultId;

    useMemo(() => {
        tracker.trackWith('checkout_thank_you', 'start_page_loaded', {id})
        if (status) {
            tracker.trackWith('checkout_thank_you', 'page_loaded', {id, status, freeConsultId})
        }
    }, [id, status, freeConsultId]);




    return (
        <div className="cartomante-detail">

            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div id="account-detail">
                                {configurations?.businessProfile=='ISA' && <IsaThankYouPage orderId={id} order={currentData}/>}
                                {configurations?.businessProfile=='MYC' && <MycThankYouPage order={currentData} siteDomain={configurations.siteDomain!!}/>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ThankYouPage;