import {Option} from "./EditableSelect";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";


export const stateOptionsIT: Option[] = [
    { label: 'Corso Garibaldi 25',value:"1"},
    { label: 'Via Roma 12',value:"2"},
    { label: 'Via Da Lurago 19',value:"3"},
    { label: 'Via Mestre 10',value:"4"},
    { label: 'Via dei Caduti 1',value:"5"},
    { label: 'Corso Sempione',value:"6"},
];

export const stateOptionsCH: Option[] = [
    { label: 'Via Lugano 10',value:"1"},
    { label: 'Via Losanne 10',value:"2"},
    { label: 'Vie di Ginevra 2',value:"3"},
    { label: 'Vie di Ginevra 2',value:"4"},
    { label: 'Vie Lucino 1',value:"5"},

];

export const stateOptionsFR: Option[] = [
    { label: 'Avenue des Champs-Élysées 1',value:"2"},
    { label: 'Saint-Germain-des-Prés. 4',value:"3"},
    { label: 'Rue Saint Honoré 5',value:"4"},
    { label: 'Rue de Rivoli in Le Marais. 88',value:"5"},
    { label: 'Boulevard Haussmann. 1',value:"6"},

];


export const stateOptionsES: Option[] = [
    { label: 'Calle Gran Vía 111.',value:"1"},
    { label: 'Calle de Preciados 5.',value:"2"},
    { label: 'Calle de Serrano 1.',value:"3"},
    { label: 'El Rastro su Calle de la Ribera de Curtidores. 114',value:"4"},
];


export const stateOptionsDE: Option[] = [
    { label: 'Unter den Linden 1',value:"1"},
    { label: 'Kurfürstendamm 17',value:"2"},
    { label: 'Alexanderplatz 55',value:"3"},
    { label: 'Potsdamer Platz 14',value:"4"},
    { label: 'Pariser Platz 14',value:"5"},
];

export const stateOptionsUK: Option[] = [
    { label: 'Carnaby Street 1',value:"1"},
    { label: 'Oxford Street. 12',value:"2"},
    { label: 'King\'s Road 10',value:"3"},
    { label: 'Abbey Road 1',value:"4"},
    { label: 'Downing Street. 134',value:"5"},
];

export const stateOptionsUS: Option[] = [
    { label: 'Broadway',value:"1"},
    { label: 'Bowery',value:"2"},
    { label: 'Houston Street.',value:"3"},
    { label: 'Canal Street.',value:"4"},
    { label: 'Avenue of the Americas',value:"5"},
];

export const initialState:Record<string,Option[]>={
    "it":stateOptionsIT,
    "uk":stateOptionsUK,
    "de":stateOptionsDE,
    "fr":stateOptionsFR,
    "us":stateOptionsUS,
    "ch":stateOptionsCH,
    "es":stateOptionsES
}

interface AddAddress {
    option:Option;
    country:string;
}


export const addressSlice = createSlice({
    name: 'address',
    initialState,
    reducers: {
        addAddress: (state, action: PayloadAction<AddAddress>) => {
           if(state[action.payload.country]){
               state[action.payload.country].push(action.payload.option)
           }
        }
    },
})

export const { addAddress } = addressSlice.actions

