import React, {useMemo} from "react";
import {useParams} from "react-router-dom";
import {useGetOrderQuery, useGetOrderStatusQuery} from "../checkout/api";
import {useTracker} from "../tracking/tracking.hook";
import {BusinessProfile, Service} from "../checkout/types";
import ConsultRequest from "./ConsultRequest";

interface Props {
    service?: Service;
    fortuneTeller?: string
}

const OrderConsultRequest: React.FC<Props> = ({}) => {

    let {id} = useParams();


    const tracker = useTracker();

    const order = useGetOrderQuery(id as string)


    useMemo(() => {
        tracker.track('request_private_consult', 'page_loaded', id)
    }, [id]);

    const currentData = order.currentData;
    const service = currentData?.service;

    const fortuneTeller = currentData?.fortuneTeller?.name

    return (
        <>
            {service && <ConsultRequest id={id!} fortuneTeller={fortuneTeller} service={service} billing={currentData.billing} businessProfile={currentData.businessProfile ? currentData.businessProfile : BusinessProfile.ISA}/>}
        </>
    )
}

export default OrderConsultRequest
