import {OrderStatus, PaymentStatus} from "../checkout/types";
import ThankYouBackGround from './myc/thank_you1.png'
import {Link} from "react-router-dom";
import React from "react";
import {useTracker} from "../tracking/tracking.hook";
import {useGetConfigurationsQuery} from "../checkout/api";

interface Props {
  order?: OrderStatus,
}

interface Props {
  order?: OrderStatus,
}

interface OtherProps extends Props{
  siteDomain:string;
}



const SectionTitle = ( { slogan, title, classes }:{slogan:string,title:string,classes:any} ) => {
  return (
      <div className={`section-title ${classes ? classes : ''}`}>
        <span className="pre-title" dangerouslySetInnerHTML={{__html: slogan}}></span>

        <h3 className="title" dangerouslySetInnerHTML={{__html: title}}></h3>
      </div>
  )
}


const ThankYouError = ( {order,siteDomain}: { order: OrderStatus, siteDomain:string } ) => {

  return (
      <div>
        <div className="client-information">
          <img className="radius-round" height={"50px"} width={"50px"} src={order.fortuneTeller.imageUri}/>
          <div className="info"><h5 className="title">{order.fortuneTeller.name}</h5></div>
        </div>

        <p className="description">{`“ Purtroppo qualcosa non ha funzionato. Possiamo aiutarti a risolvere il problema comunica il codice ” ${order?.id}`}</p>


        <div className="testimonial-nav-wrapper">
          <div className="single-thumbnail">
            <Link className="edu-btn" to="#">Contattaci<i className="icon-arrow-right-line-right"></i></Link>
            <div className="edu-btn">Home Page<i className="icon-arrow-right-line-right"></i></div>
            <div className="loader-container">
              <div className="circle-loader-wrap">
                <div className="left-wrap">
                  <div className="loader"></div>
                </div>
                <div className="right-wrap">
                  <div className="loader"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

const ThankYouSuccess = ( { order }: { order: OrderStatus } ) => {

  const tracker = useTracker();

  console.log(order);

  return (
      <div>
        <div className="client-information">
          <img className="radius-round" height={"50px"} width={"50px"} src={order.fortuneTeller.imageUri}/>
          <div className="info"><h5 className="title">{order.fortuneTeller.name}</h5></div>
        </div>

        <p className="description">“ Per richiedere il servizio, puoi cliccare sul pulsante "Chiedi ora". Se preferisci richiederlo più tardi, puoi aprire il link che ti abbiamo inviato via email
          oppure salvarlo tra i tuoi preferiti.
          Se hai bisogno di aiuto, siamo qui per te!”</p>


        <div className="testimonial-nav-wrapper">
          <div className="single-thumbnail">

            <Link onClick={() => {
              tracker.trackWith('checkout_thank_you', 'click_request_consult', {id:order.id})
            }} className="edu-btn" to={`/consult-request/${order.id}`}>Chiedi ora<i className="icon-arrow-right-line-right"></i></Link>
            <div className="loader-container">
              <div className="circle-loader-wrap">
                <div className="left-wrap">
                  <div className="loader"></div>
                </div>
                <div className="right-wrap">
                  <div className="loader"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}


const MycThankYouPage = ({order, siteDomain}: OtherProps) => {


  if (!order)
    return <></>


  return <div className="eduvibe-home-two-testimonial edu-testimonial-area testimonial-card-box-bg edu-section-gap bg-image">
    <div className="container">
      <div className="edu-testimonial-activation testimonial-item-3 mt--40 edu-slick-button">
        <div className="container eduvibe-animated-shape">
          <div className="row">
            <div className="col-lg-6">
              <div className="thumbnail">
                <div className="circle-image-wrapper">
                  <img className="radius-round" height={"400px"} width={"400px"} src={ThankYouBackGround} alt="Testimonial Section Thumb"/>
                  <div className="circle-image">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">

              {(![PaymentStatus.ALREADY_USED,PaymentStatus.COMPLETED, PaymentStatus.VERIFIED].find(el => {
                    return el === order.status
                  })) &&
                  <div className="testimonial-wrapper">
                    <SectionTitle
                        classes="text-start mb--40"
                        slogan="Attenzione"
                        title="Qualcosa non va"
                    />
                    <ThankYouError order={order} siteDomain={siteDomain}/>

                  </div>}

              {(order.status && (order.status === PaymentStatus.ALREADY_USED)) &&
                  <div style={{marginLeft: "auto", marginRight: "auto", textAlign: "center"}}>
                    <p className="CorpoMessaggio_Risposta center"><strong
                        style={{fontSize: "28px", color: "darkgreen"}}>La tua richiesta è stata inviata.</strong></p>

                    <br/>
                    <br/>
                    <p className="CorpoMessaggio_Risposta center">
                      <strong>
                        <u>Riceverai la risposta all'indirizzo email indicato nel form precedente </u>
                        <br/>
                        <u>Controlla indirizzo email che hai fornito, se non hai ricevuto nulla controlla la casella "SPAM"</u>
                      </strong>

                      <br/>
                    </p>

                    <a className="edu-btn" href={siteDomain}>Home Page<i className="icon-arrow-right-line-right"></i></a>

                  </div>}

              {([PaymentStatus.COMPLETED, PaymentStatus.VERIFIED].find(el => {
                    return el === order.status
                  })) &&
                  <div className="testimonial-wrapper">
                    <SectionTitle
                        classes="text-start mb--40"
                        slogan="Grazie"
                        title="Il tuo consulto è pronto per essere richiesto"
                    />
                    <ThankYouSuccess order={order}/>

                  </div>}


              {(!order.status || order.status === PaymentStatus.VERIFY) &&
                  <div className="thank-you-loading-wait-completion">
                    <div className="lds-ellipsis">
                      <span/>
                      <span/>
                      <span/>
                    </div>
                  </div>
              }


            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

}

export default MycThankYouPage;