import React, {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {BusinessProfile, PaymentStatus} from "../checkout/types";
import {useGetOrderStatusQuery} from "../checkout/api";
import AppLayout from "./AppLayout";


const WritingLayoutTx: React.FC = () => {


    return (
        <AppLayout/>
    );

}

export default WritingLayoutTx;
