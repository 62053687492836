import React, {useMemo} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useSearchOrderQuery} from "../checkout/api";
import {useTracker} from "../tracking/tracking.hook";
import {Service} from "../checkout/types";
import ConsultRequest from "./ConsultRequest";

interface Props {
    service?: Service;
    fortuneTeller?: string
}

const TxIdConsultRequest: React.FC<Props> = ({}) => {

    const [searchParams] = useSearchParams()

    const navigate = useNavigate();
    const tracker = useTracker();

    const  txId = searchParams.get("tx");

    const order = useSearchOrderQuery(txId as string)


    const currentData = order.currentData;
    if(currentData && currentData.status !== "CONSULT_TO_BE_REQUESTED"){
        navigate('/payment-error/' + txId);
    }



    useMemo(() => {
        if(txId)
            tracker.track('request_private_consult', 'page_loaded', txId)
    }, [txId]);

    const service = currentData?.service;

    const fortuneTeller = currentData?.cartomante

    return (
        <>
            {service && txId && <ConsultRequest id={txId} fortuneTeller={fortuneTeller} service={service} billing={true} businessProfile={currentData.businessProfile}/>}
        </>
    )
}

export default TxIdConsultRequest
