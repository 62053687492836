import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import {api} from "../api";
import {citySlice} from "../features/component/citySlice";
import {addressSlice} from "../features/component/addressSlice";

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    city: citySlice.reducer,
    address: addressSlice.reducer,

  },
  middleware: (getDefaultMiddleware) => {
    let defaultMiddleware = getDefaultMiddleware();
    let middleware = api.middleware;
    return defaultMiddleware.concat(middleware);
  },
});


export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
