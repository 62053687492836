import React from "react";

interface Props {
    fortuneTeller?:string;
    siteDomain?:string;
}

const BreadCamp: React.FC<Props> = ({fortuneTeller,siteDomain})=>{

    return (
        <div className="breadcrumb_section bg_light_pan page-title-mini">
            {
                siteDomain && <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                        </div>
                        <div className="col-md-6">
                            <ol className="breadcrumb justify-content-md-end black">
                                <li className="breadcrumb-item" ><a href={siteDomain}>Home</a></li>
                                <li className="breadcrumb-item"><a
                                    href={`${siteDomain}/cartomante/${fortuneTeller}`}>Cartomante {fortuneTeller}</a></li>
                                <li className="breadcrumb-item"><a href="#">Checkout</a></li>
                            </ol>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default BreadCamp;
